import React , { Component } from 'react';

class JobCard extends Component {
    render() { 
        return (  
            <div className='jobs opportunity card'>
                    <div dangerouslySetInnerHTML={{__html: this.props.content}} />
                    <a href={this.props.link} className='jobs opportunity card button'>Apply Now</a>
                </div>
        );
    }
}
 
export default JobCard;
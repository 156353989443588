import React, { Component } from 'react';
import JobCard from "./jobs/JobCard.js";
import Twitter from "./jobs/Twitter.js";
import JobData from "../data/Jobs.json";

class Jobs extends Component {

    state = {  }

    render() { 
        var jobArray = [];
        for (var i = 0; i < JobData.jobs.length; i++){
            jobArray.push(<JobCard link={JobData.jobs[i].link} content={JobData.jobs[i].content} />)
        }

        return ( 
        <div id='jobs' className='jobs wrapper'>
            <div className='jobs opportunity'>
                <div className='jobs opportunity title'>Job Opportunities</div>
                {jobArray}
            </div>
            <div className='jobs twitter'>
                <Twitter />
            </div>
        </div> );
    }
}
 
export default Jobs;
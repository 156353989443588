import Home from "./pages/Home.js";
import BellyRiver from "./pages/BellyRiver.js";
import FortyMileVentures from "./pages/FortyMileVentures.js";
import KamperFarmFresh from "./pages/KamperFarmFresh.js";
import BuyNowForm from "./pages/BuyNowForm.js";
import ScrollToTop from "./components/ScrollToTop.js";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import './App.css';
import "./css/style.css";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
            <Route path='/' element={ <Home/> } exact />
            <Route path='/BellyRiverSeedPotatos' element={ <BellyRiver/> } exact/>
            <Route path='/FortyMileVentures' element={ <FortyMileVentures/>} exact />
            <Route path='/KamperFarmFresh' element={ <KamperFarmFresh/> } exact />
            <Route path='/BuyNowForm' element={ <BuyNowForm/> } exact />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

import React, { Component } from 'react';

class Title extends Component {
    render() { 
        return (
        <div className='titleWrapper'>
            <div className='title'>{this.props.title}</div>
        </div>
          );
    }
}
 
export default Title;
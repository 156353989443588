import React, {useState} from 'react';
import Button from '../Button.js';
import {Link} from 'react-scroll';
import {Link as RouterLink} from 'react-router-dom';
import '../../css/style.css';
 
function BellyRiverHeader(){
    const [style, setStyle] = useState(false);
    const handleToggle = () => {
        setStyle(!style);
    }
    const closeDropdown = () =>{
        setStyle(false);
    }

    return(
        <div>
            <div className='header'>
                <img className='header image' src='./Images/Logos/belly-river-logo.png' alt='Kamper Farm Fresh Logo' />
                <div className='header nav'>
                    <Button version='button empty' title='Home' link='/' useRouter={true}/>
                    <Button version='button empty' title='Forty Mile Ventures' link='/FortyMileVentures' useRouter={true}/>
                    <Button version='button empty' title='Kamper Farm Fresh' link='/KamperFarmFresh' useRouter={true}/>
                    <Button version='button empty' title='Contact Us' link='contactUs' useRouter={false}/>
                    <Button version='button large' title='Buy Now' link='/BuyNowForm' useRouter={true}/>
                </div>
            </div>
            <div className='headerMobile'>
                <div className='mainLogo'>
                    <img className='mainLogo' src='./Images/Logos/belly-river-logo.png' alt='Belly River Logo' />
                </div>
                <div className='hamburgerWrapper'>
                    <div className='hamburger' onClick={handleToggle}>
                        <div className='hamburger element'></div>
                        <div className='hamburger element'></div>
                        <div className='hamburger element'></div>
                    </div>
                </div>
            </div>
            <div className={style ? "mobileDropdown show" : "mobileDropdown hidden"}>
                <RouterLink
                    to='/'
                    className='mobileDropdown show text'
                >
                    Home
                </RouterLink>
                <br />
                <RouterLink
                    to='/FortyMileVentures'
                    className='mobileDropdown show text'
                >
                    Forty Mile Ventures
                </RouterLink>
                <br />
                <RouterLink
                    to='/KamperFarmFresh'
                    className='mobileDropdown show text'
                >
                    Kamper Farm Fresh
                </RouterLink>
                <br />
                <Link
                    to='contactUs'
                    smooth={true}
                    offset={-25}
                    duration={500}
                    isDynamic={true}
                    className='mobileDropdown show text'
                    onClick={closeDropdown}
                >
                Contact Us
                </Link>
                <br />
                <RouterLink
                    to='/BuyNowForm'
                    className='mobileDropdown show text'
                >
                    Buy Now
                </RouterLink>
            </div>
        </div>
    );
}

export default BellyRiverHeader;
import React, { Component } from 'react';
import {Link} from "react-router-dom";

class FolioCardRight extends Component {
    /* READ ME:
    The content for this component can be filled as needed.
    Use p tags for any content you want to display as plain text and give it the className pullquote to display as a pull quote
    use <p className='pullquote'>This is a simple "Pull Quote" example.</p> for quotes
    */
    render() { 
        return ( 
        <div className='folioCard'>
            <div className='folioCard logo'>
                <img src='./Images/Logos/forty-mile-ventures-logo.png' alt='Forty Mile Ventures Logo' />
            </div>
            <div className='folioCard content'>
                <div>
                <p>Forty Mile Ventures is a family owned, progressive farm operation located in the southern part of Canadian province Alberta.</p>
                <p>We raise a variety of crops primarily under center pivot irrigation. Our production locations are near to the towns of Burdett, Vauxhall and Hays.</p>
                </div>
            </div>
            <div className='folioCard button' >
                <Link to="/FortyMileVentures" className='folioButton'>Learn More</Link>
            </div>
        </div> );
    }
}
 
export default FolioCardRight;
import Header from "../components/headers/FortyMileVenturesHeader.js";
import Video from "../components/heroVideos/FortyMileVenturesVideo.js";
import Content from "../components/content/FortyMileVenturesContent.js";
import Footer from "../components/Footer.js";

function FortyMileVentures(){
    return(
    <div>
        <Header />
        <Video />
        <Content />
        <Footer form={true} />
    </div>
    
    );
}

export default FortyMileVentures;
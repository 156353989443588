import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class FolioCardCenter extends Component {
    /* READ ME:
    The content for this component can be filled as needed.
    Use p tags for any content you want to display as plain text and give it the className pullquote to display as a pull quote
    use <p className='pullquote'>This is a simple "Pull Quote" example.</p> for quotes
    */
    render() { 
        return ( 
        <div className='folioCard'>
            <div className='folioCard logo'>
                <img src='./Images/Logos/farm-fresh-logo.png' alt='' />
            </div>
            <div className='folioCard content'>
                <p>Kamper Farm Fresh is a fresh pack facility in Southern Alberta. We are located between Taber and Vauxhall on HWY 36.  We wash, size, and grade primarily table potatoes.</p>
                <p>We serve customers primarily in Western Canada, but we also ship to Eastern Canada and the United States. The potatoes that we handle in our facility are grown at our own farms.</p>
            </div>
            <div className='folioCard button' >
                <Link to="/KamperFarmFresh" className='folioButton'>Learn More</Link>
            </div>
        </div> );
    }
}
 
export default FolioCardCenter;
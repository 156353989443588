import BellyRiverHeader from "../components/headers/BellyRiverHeader.js";
import Video from "../components/heroVideos/BellyRiverVideo.js";
import Content from "../components/content/BellyRiverContent.js";
import Footer from "../components/Footer.js";

function BellyRiver(){
    return(
    <div>
        <BellyRiverHeader />
        <Video />
        <Content />
        <Footer form={true} />
    </div>
    );
}

export default BellyRiver;
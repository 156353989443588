import React, { Component } from 'react';
import Title from "./Title.js";
import Left from "./portfolio/FolioCardLeft.js";
import Right from "./portfolio/FolioCardRight.js";
import Center from "./portfolio/FolioCardCenter.js";

class Portfolio extends Component {
    state = {  }
    render() { 
        return (  
        <div id='portfolio'>
            <Title title='our portfolio' />
            <div className='portfolioWrapper'>
                <Left />
                <Center />
                <Right />
            </div>
        </div>
        );
    }
}
 
export default Portfolio;
import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import EmailJS from 'emailjs-com';
import "../../css/style.css";

// Paulo's reCAPTCHA Site Key : 6Ld2hAkcAAAAAAWYHE1KRkqGLThYLmixH7_r6kGZ

class BuyNowContent extends React.Component {
    
    constructor(props){
        super(props);

        //Update All ID's
        this.state = {
            name: null,
            email: null,
            phone: null, 
            message: null,
            subject: null,
            isVerified: false,
            templateSeedPotatoesID: 'templateBuyNow',
            templateOtherID: 'templateBuyNow',
            serviceID: 'service_amwx52m',
            userID: 'user_vrWl3qwt58MxBYzxFioeH'
        }

        this.checkFields = this.checkFields.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit  = this.handleSubmit.bind(this);
        this.captchaSuccess = this.captchaSuccess.bind(this);
    }

    checkFields(){
        if (
            this.state.name !== null ||
            this.state.name !== '' ||
            this.state.email !== null ||
            this.state.email !== '' ||
            this.state.phone !== null ||
            this.state.phone !== '' ||
            this.state.message !== null ||
            this.state.message !== '' ||
            this.state.subject !== null ||
            this.state.subject !== ''
        ){
            return true;
        }
        else return false;
    }
    
    handleChange(event){
        const name = event.target.name;
        const value = event.target.value;

        this.setState({[name]: value})
    }

    handleSubmit(event){
        event.preventDefault();

        if (this.state.isVerified === true){
            if (this.checkFields()){

                let variables = {
                    name: this.state.name,
                    email: this.state.email,
                    phone: this.state.phone,
                    message: this.state.message,
                    subject: this.state.subject
                }
                if (this.state.subject === 'Seed Potatoes'){
                    EmailJS.init(this.state.userID);
                    //use templatePotatoSeedsID
                    EmailJS.send(this.state.serviceID, this.state.templateOtherID, variables)
                    .then(res => {console.log('Email succesfully sent!')})
                    .catch(err => console.error('Email Failed'));
                    console.log("Email Sent");
                    alert("Email Sent");

                }
                else{
                    EmailJS.init(this.state.userID);
                    // use templateOtherID
                    EmailJS.send(this.state.serviceID, this.state.templateOtherID, variables)
                    .then(res => {console.log('Email succesfully sent!')})
                    .catch(err => console.error('Email Failed'));
                    console.log("Email Sent");
                    alert("Email Sent");
                }  
            }
            else{
                console.log("Fields incomplete");
                alert("Please Fill in All Fields");
            }
        }
        else{
            console.log("captcha failed")
            alert("Please Complete the Captcha")
        }
    }
    
    captchaSuccess(){
        this.setState({isVerified: true});
    }
    
    render(){
        return(
            <div className='titleWrapper'>
                <div className='title'>Buy Now Form</div>
                <div className='pageContent' style={{textAlign: "Center"}}>
                    <form onSubmit={this.handleSubmit}>
                        <label>Name:</label>
                        <br />
                        <input 
                            id='name'
                            type='text'
                            name='name'
                            placeholder='Name...'
                            value={this.state.name}
                            onChange={this.handleChange}
                            required
                            className='buyNowForm'
                        />
                        <br />
                        <label>Email:</label>
                        <br />
                        <input 
                            id='email'
                            type='email'
                            name='email'
                            placeholder='Email...'
                            value={this.state.email}
                            onChange={this.handleChange}
                            required
                        />
                        <br />
                        <label>Phone Number:</label>
                        <br />
                        <input 
                            id='phone'
                            type='tel'
                            name='phone'
                            pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                            placeholder='Phone Number'
                            value={this.state.phone}
                            onChange={this.handleChange}
                            required
                        />
                        <br />
                        <label>Product:</label>
                        <br />
                        <select
                            id='subject'
                            name='subject'
                            onChange={this.handleChange}
                        >
                            <option value='' selected disabled hidden>Choose Product</option>
                            <option value='Seed Potatoes'>Seed Potates</option>
                            <option value='Potatoes'>Potatoes</option>
                            <option value='Sugar Beets'>Sugar Beets</option>
                            <option value='Crops'>Other Crops</option>
                        </select>
                        <br />
                        <label>Message:</label>
                        <br />
                        <textarea
                            id='message'
                            name='message'
                            placeholder='Message...'
                            value={this.state.message}
                            onChange={this.handleChange}
                            required
                        />
                        <br />
                        <div className='captcha'>
                            <ReCAPTCHA
                                sitekey='6Ld2hAkcAAAAAAWYHE1KRkqGLThYLmixH7_r6kGZ'
                                render='explicit'
                                onChange={this.captchaSuccess}
                            />
                        </div>
                        <br />
                        <input 
                            type='submit'
                            value='Send Message'
                            className='submit' 
                        />
                    </form>
                </div>
            </div>
        );
    }
}

export default BuyNowContent;
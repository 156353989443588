/** @format */

import React, { useEffect } from "react";
// REMEMBER TO CHANGE TWITTER TO RELEVANT
const Twitter = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://platform.twitter.com/widgets.js";
    document.getElementsByClassName("twitter-news")[0].appendChild(script);
  }, []);
  return (
    <div className='twitter-news'>
      <a
        className='twitter-timeline'
        data-tweet-limit='5'
        data-chrome='noheader nofooter noborder'
        href='https://twitter.com/KamperGroup'>
        Tweets by The Kamper Group
      </a>
    </div>
  );
};

export default Twitter;

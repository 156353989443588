import Header from "../components/headers/KamperFarmFreshHeader.js";
import Video from "../components/heroVideos/FarmFreshVideo.js";
import Content from "../components/content/FarmFreshContent.js";
import Footer from "../components/Footer.js";

function KamperFarmFresh(){
    return(
    <div>
        <Header />
        <Video />
        <Content />
        <Footer form={true} />
    </div>
    );
}

export default KamperFarmFresh;
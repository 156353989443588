import React, { Component } from 'react';
import '../../css/style.css';
import VideoData from "../../data/BellyRiverVideos.json";

class BellyRiverVideo extends Component {

    render() { 
        var length = VideoData.videos.length;
        var index = Math.floor(Math.random() * length);
        var data = VideoData.videos[index];


        return (
        <div className='mainVideo'>
            <div className='mainVideo image'>
                <img src='./test.png' alt='' />
            </div>
            <div className='mainVideo video'>
                <video autoPlay loop muted>
                    <source src={data} type='video/mp4' />
                </video>
            </div>
        </div>
        );
    }
}
 
export default BellyRiverVideo;
import React, { Component } from 'react';
import Form from "./FooterForm.js";
import { Link } from "react-router-dom";

class Footer extends Component {
    state = {  }

    checkForm(){
        if (this.props.form === true){
            return (<div><h1>Send Us a Message</h1><Form /></div>);
        }
    }

    render() { 
        return ( 
        <div className='footer' id='contactUs'>
            <div>
                {this.checkForm()}
            </div>
            <div>
                <h1>Links</h1>
                <br />
                <h2><Link to='/BellyRiverSeedPotatos'>Belly River Seed Potatos</Link></h2>
                <h2><Link to='/KamperFarmFresh'>Kamper Farm Fresh</Link></h2>
                <h2><Link to='FortyMileVentures'>Forty Mile Ventures</Link></h2>
            </div>
            <div>
                <h1>Contact Us</h1>
                <h2>50-491 Wt Hill Blvd S</h2>
                <h2>Lethbridge AB, Canada, T1J 1Y6</h2>
                <br />
                <h2>Phone: (403) 380-6626</h2>
                <br />
                <h2>Copyright<span dangerouslySetInnerHTML={{ "__html": "&copy;"}} /> Kamper Group 2021</h2>
                <h2>Site by Joshua Rist</h2>
            </div>
        </div> 
        );
    }
}
 
export default Footer;
function BellyRiverContent(){
    return(
        <div className='titleWrapper'>
            <div className='title'>Belly River Seed Potatoes</div>
            <div className='pageContent'>
                <img className='pageContent pullquote' src='./Images/Logos/belly-river-old-logo.png' alt='Belly River Logo' style={{maxWidth: "400px"}}/>
                <p>Belly River Seed Potatoes Ltd is a family run farming operation, located in southern Alberta. At BRSP we strive to grow high quality seed potatoes in a sustainable manner.</p>                
                <p>We grow a wide range of varieties that are used in the French fry, table and chip markets. For most varieties we have multiple generations available.</p>
            </div>
        </div>
    );
}

export default BellyRiverContent;
import React from "react";
import Header from "../components/headers/MainHeader.js";
import Video from "../components/heroVideos/MainVideo.js";
import Image from "../components/MainImage.js";
import Portfolio from "../components/Portfolio.js";
import Jobs from "../components/Jobs.js";
import Footer from "../components/Footer.js";

export default function Home(){
    return(
        <div>
            <Header />
            <Video />
            <Portfolio />
            <Image />
            <Jobs />
            <Footer form={true} />
        </div>
    );
}

import React from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import EmailJS from 'emailjs-com';

// Paulo's reCAPTCHA Site Key: 6Ld2hAkcAAAAAAWYHE1KRkqGLThYLmixH7_r6kGZ

class FooterForm extends React.Component {
    constructor(props){
        super(props);
        //Change all ID's
        this.state = {
            name: '',
            email: '',
            message: '', 
            isVerified: false,
            userID: 'user_vrWl3qwt58MxBYzxFioeH',
            serviceID: 'service_amwx52m',
            templateID: 'templateFooter'
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.captchaSuccess = this.captchaSuccess.bind(this);
    }

    handleChange(event){
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        })
    }

    handleSubmit(event){
        // Template ID from your emailJS page
        // This will change with the transfer of accounts and will need to be updated
        // Note also update userID in ./public/index.html header
        let variables = {
            name: this.state.name,
            email: this.state.email,
            phone: "N/A",
            message: this.state.message,
            subject: "Contact Us"
        }
        if (this.state.isVerified === true){

            if (this.checkName() || this.checkEmail || this.checkMessage()){
                
                EmailJS.init(this.state.userID);
            
                EmailJS.send(this.state.serviceID, this.state.templateID, variables)
                .then(res => {console.log('Email succesfully sent!')})
                .catch(err => console.error('Email Failed'));
                console.log("Email sent");
                }
            else{
                console.log("Fields Incomplete");
                alert("Please Fill in All Fields");
            }

            
        }
        else{
            alert("Please Complete the Captcha");
            console.log("captcha failed");
        }        
    }

    checkName(){
        if (this.state.userName !== null || this.state.userName !== ''){
            return true;
        }
        else{
            return false;
        }
    }

    checkEmail(){
        if (this.state.userEmail !== null || this.state.userEmail !== ''){
            return true;
        }
        else{
            return false;
        }
    }

    checkMessage(){
        if (this.state.message !== null || this.state.message !== ''){
            return true;
        }
        else{
            return false;
        }
    }

    captchaSuccess(){
        this.setState({isVerified: true});
    }
    
  render() {
	return (

        <form onSubmit={this.handleSubmit}>
            <input 
                id='name'
                type='text' 
                name='name'
                placeholder='Name...' 
                value={this.state.name}
                onChange={this.handleChange}
                required
            />
            <br />
            <input 
                id='email'
                type='email' 
                name='email'
                placeholder='Email...' 
                value={this.state.email}
                onChange={this.handleChange}
                required
            />
            <br />
            <textarea 
                id='message'
                name='message'
                placeholder='Message...'
                value={this.state.message}
                onChange={this.handleChange}
                required 
            />
            <br />
            <div className='captcha'>
            <ReCAPTCHA 
                sitekey='6Ld2hAkcAAAAAAWYHE1KRkqGLThYLmixH7_r6kGZ' 
                render='explicit'
                onChange={this.captchaSuccess}
            />
            </div>
            <br />
            <input type='submit' value='Send Message' className='submit' />
        </form>
    );
  }
}

export default FooterForm;
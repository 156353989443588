import React, {useState} from 'react';
import Button from '../Button.js';
import { Link } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';
import '../../css/style.css';
 
function MainHeader(){
    const [style, setStyle] = useState(false);
    const handleToggle = () => {
        setStyle(!style);
    }
    const closeDropdown = () => {
        setStyle(false);
    }

    return(
        <div>
            <div className='header'>
                <img className='header image' src='./Images/Logos/main-logo.png' alt='Kamper Farm Fresh Logo' />
                <div className='header nav'>
                    <Button version='button empty' title='Portfolio' link='portfolio' useRouter={false}/>
                    <Button version='button empty' title='History' link='history' useRouter={false}/>
                    <Button version='button empty' title='Join Us' link='jobs' useRouter={false}/>
                    <Button version='button empty' title='Contact Us' link='contactUs' useRouter={false}/>
                    <Button version='button large' title='Buy Now' link='/BuyNowForm' useRouter={true}/>
                </div>
            </div>
            <div className='headerMobile'>
                <div className='mainLogo'>
                    <img className='mainLogo' src='./Images/Logos/main-logo.png' alt='Kamper Farm Fresh Logo' />
                </div>
                <div className='hamburgerWrapper'>
                    <div className='hamburger' onClick={handleToggle}>
                        <div className='hamburger element'></div>
                        <div className='hamburger element'></div>
                        <div className='hamburger element'></div>
                    </div>
                </div>
            </div>
            <div className={style ? "mobileDropdown show" : "mobileDropdown hidden"}>
            <Link
                    to='portfolio'
                    smooth={true}
                    offset={-25}
                    duration={500}
                    isDynamic={true}
                    className='mobileDropdown show text'
                    onClick={closeDropdown}
            >
                Portfolio
            </Link>
            <br />
            <Link
                    to='history'
                    smooth={true}
                    offset={-25}
                    duration={500}
                    isDynamic={true}
                    className='mobileDropdown show text'
                    onClick={closeDropdown}
            >
                History
            </Link>
            <br />
            <Link
                    to='jobs'
                    smooth={true}
                    offset={-25}
                    duration={500}
                    isDynamic={true}
                    className='mobileDropdown show text'
                    onClick={closeDropdown}
            >
                Jobs
            </Link>
            <br />
            <Link
                    to='contactUs'
                    smooth={true}
                    offset={-25}
                    duration={500}
                    isDynamic={true}
                    className='mobileDropdown show text'
                    onClick={closeDropdown}
            >
                Contact Us
            </Link>
            <br />
            <RouterLink
                    to='/BuyNowForm'
                    className='mobileDropdown show text'
                >
                    Buy Now
                </RouterLink>
            </div>
        </div>
    );
}
 
export default MainHeader;
import React, { Component } from 'react';
import {Link} from "react-router-dom";

class FolioCardLeft extends Component {
    /* READ ME:
    The content for this component can be filled as needed.
    Use p tags for any content you want to display as plain text and give it the className pullquote to display as a pull quote
    use <p className='pullquote'>This is a simple "Pull Quote" example.</p> for quotes
    */
    render() { 
        return ( 
        <div className='folioCard'>
            <div className='folioCard logo'>
                <img src='./Images/Logos/belly-river-logo.png' alt='' />
            </div>
            <div className='folioCard content'>
                <p>Belly River Seed Potatoes Ltd is a family run farming operation, located in southern Alberta. At BRSP we strive to grow high quality seed potatoes in a sustainable manner.</p>                
                <p>We grow a wide range of varieties that are used in the French fry, table and chip markets. For most varieties we have multiple generations available.</p>
            </div>
            <div className='folioCard button' >
                <Link to='/BellyRiverSeedPotatos' className='folioButton'>Learn More</Link>
            </div>
        </div> );
    }
}
 
export default FolioCardLeft;
import React, { Component } from 'react';
import '../css/style.css';
import ImageData from "../data/MainImages.json";
import MobileImageData from "../data/MainImages.json";

class MainVideo extends Component {
    //<p className='historyContent pullquote'>Feugiat scelerisque varius morbi enim nunc.</p>
    state = {  }

    render() { 
        var length;
        var index;
        var data;
        if (window.innerwidth < 500){
            length = MobileImageData.images.length;
            index = Math.floor(Math.random() * length);
            data = MobileImageData.images[index];
        }
        else{
            length = ImageData.images.length;
            index = Math.floor(Math.random() * length);
            data = ImageData.images[index];
        }


        return (
        <div className='mainImage' id='history'>
            <div className='mainImage image'>
                <img src='./test2.png' alt='' />
                <div className='titleWrapper history'>
                    <div className='title history'>Our Values</div>
                    <div className='historyContent'>
                    <p>Our mission is to build up and continually improve our farm operations by growing high quality crops in a sustainable manner.</p>                    
                    <p>This is accomplished when we continue to build value in our companies, treat the resources we are entrusted with sustainably, and continue to have a viable operation for the next generations.</p>
                    </div>
                    <div className='title history'>Our History</div>
                    <div className='historyContent'>
                    <p>The Kamper family has farmed for multiple centuries in the Netherlands. For many generations our family farmed in the Mijdrecht (Utrecht area). In 1890 the family moved to the newly reclaimed Haarlemmermeer polder. This new farmland on the former lake bottom provided opportunities for Maarten Kamper (1835-1917) and his 7 sons. The family settled on a farm on the Yweg, close to Boesingheliede. In 1902, Pieter Kamper (1875-1945) was able to start a mixed farm on the Hoofdweg 295. His son Maarten (1920-2011) took the farm over in 1945. Beside farming, he was actively engaged in local politics, school boards and church committees.  During the 1950s and 1960s the farm focussed more and more on crop farming. By the late 1960s the last cows left. Kees (1955) took over the farm in 1976 and expanded the operation substantially in the years that followed. The focus of the farm was growing potatoes. Other crops in the rotation included sugar beets, onions, and wheat. Kees married Gerda van der Vlugt (1956) and they had three sons: Martien (1982), Pieter (1983) and Henk Jan (1987). </p>
                    <p>In 1999, a brand-new farm was built about 200 meters from the existing location. The relocation was necessary because of the trajectory of the new highway A5 went straight through the existing farm. In the following years it became clear that all three sons had the ambition to become part of the farming operation. A couple years were spent to figure out where to move to, since there was not enough future to stay at that location. In the fall of 2008, the family sold the majority of the farm, and prepared to make the move to Southern Alberta.  The Dutch operations were scaled back in the years following.</p>
                    <p>The Kamper family started farming in Southern Alberta in the spring of 2009. The company focusses on raising irrigated high value crops. The largest crop for the company is potatoes. Other crops include sugar beet, edible beans, canola for seed production, corn for grain, and wheat. Production locations are close to the towns of Burdett, Vauxhall, and Hays.</p>
                    </div>
                </div>
            </div>
            <div className='mainImage video'>
                <img src={data} alt='' />
            </div>
        </div>
        );
    }
}
 
export default MainVideo;
function FarmFreshContent(){
    return(
        <div className='titleWrapper'>
            <div className='title'>Kamper Farm Fresh</div>
            <div className='pageContent'>
                <p>Kamper Farm Fresh is a fresh pack facility in Southern Alberta. We are located between Taber and Vauxhall on HWY 36.  We wash, size, and grade primarily table potatoes.</p>
                <p>We serve customers primarily in Western Canada, but we also ship to Eastern Canada and the United States. The potatoes that we handle in our facility are grown at our own farms.</p>
            </div>
        </div>
    );
}

export default FarmFreshContent;
//<p className='pageContent pullquote'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
function FortyMileVenturesContent(){
    return(
        <div className='titleWrapper'>
            <div className='title'>Forty Mile Ventures</div>
            <div className='pageContent'>
                <p>Forty Mile Ventures is a family owned, progressive farm operation located in the southern part of Canadian province Alberta.</p>                
                <p>We raise a variety of crops primarily under center pivot irrigation. Our production locations are near to the towns of Burdett, Vauxhall and Hays.</p>
            </div>
        </div>
    );
}

export default FortyMileVenturesContent;
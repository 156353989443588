import React, { Component } from 'react';
import { Link } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';
import "../css/style.css";

class Button extends Component {
    state = { 
    title: this.props.title
     }

    render() { 
        if (this.props.useRouter){
            return(
                <RouterLink
                    to={this.props.link}
                    className={this.props.version}
                >
                    {this.props.title}
                </RouterLink>
            );
        }
        else{
            return( 
                <Link
                    to={this.props.link}
                    smooth={true}
                    offset={-25}
                    duration={500}
                    isDynamic={true}
                    className={this.props.version}>
                    {this.props.title}
                </Link>);
        }
    }
}
 
export default Button;